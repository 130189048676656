<template>
    <div id="createAccount" class="flex flex-column">
        <h2 class="blue clarity-margin-bottom-m">WELCOME TO CLARITY</h2>
        <div>
            <div v-if="showErrorMessage" class="flex flex-column">
                <div class="create-error-msg">
                    <div class="flex justify-content-between">
                        <div class="flex">
                            <h3 class="red"><span class="material-icons">error</span>REGISTRATION FAILED</h3>
                        </div>
                        <div class="flex grey cursor-pointer" @click="closeError">
                            <span class="material-icons-outlined">close</span>
                        </div>
                    </div>

                    <p class="body-text"><span v-html="errorMessage"></span></p>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col p-input-icon-left p-input-icon-right cl-input">
                    <i class="pi cl-email-grey-icon" />
                    <InputText type="text" placeholder="Email address..." class="p-inputtext-lg w-full" v-model="email" @keyup.enter="checkEmailAddress" />
                    <i @click="showEmailInfo" class="pi cl-info-icon" />
                </div>
                <OverlayPanel ref="op">
                    <div class="flex justify-content-between">
                        <div class="flex">
                            <h3 class="clarity-h3 blue">EMAIL ADDRESS</h3>
                        </div>
                        <div class="flex grey cursor-pointer" @click="showEmailInfo">
                            <span class="material-icons-outlined">close</span>
                        </div>
                    </div>
                    <p class="body-text">This field is required</p>
                </OverlayPanel>
            </div>
            <div class="flex flex-column">
                <div class="recaptcha-terms">
                    <p>This site is protected by reCAPTCHA and the<br>Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
                </div>
            </div>
            <!-- <Button @click="checkEmailAddress" label="Create an Account" class="clarity-gradient-orange clarity-btn clarity-margin-top-s clarity-margin-bottom-m" /> -->
            <submit-button @click="recaptcha" :loading="loadingState" loading-text="Loading..." button-text="Create an Account" class="clarity-margin-top-s clarity-margin-bottom-m buttonwidth"  ></submit-button>

            <hr>
            <p class="body-text clarity-margin-top-m clarity-margin-bottom-s">Already registered?</p>
            <router-link to="/login">
                <Button label="Login" class="clarity-gradient-grey clarity-btn" />
            </router-link>

        </div>
    </div>
</template>

<script>
import {computed, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';

import Amplify, { Auth } from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure({ ...config });

const domain = process.env.VUE_APP_COOKIE_DOMAIN;

if(domain && window.location.href.indexOf(domain) > -1) {
    Amplify.configure({
        Auth: {
            cookieStorage: {
                domain: domain,
                secure: false,
            },
        }
    });
}

import { useReCaptcha } from 'vue-recaptcha-v3';
import api from '@/core/services/api'
import {toClarityAnonRequest} from "../core/helpers";
import SubmitButton from "../components/common/layout/interactive/SubmitButton";


export default {
  components: {SubmitButton},
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref();
    const op = ref();
    const errorMessage = ref('');
    const showErrorMessage = ref(false);

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
        await store.dispatch('setLoading', {id: 'create-account', state: true})
        showErrorMessage.value = false;
        await recaptchaLoaded()
        const token = await executeRecaptcha('newuser');
        try {
            const response = await api.post(`${toClarityAnonRequest('recaptchaverify')}`, JSON.stringify({'token':token}), {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            if(response.isVerified) {
                checkEmailAddress();
            } else {
                await store.dispatch('setLoading', {id: 'create-account', state: false})
                // error message
                errorMessage.value = "reCaptcha error. Please try again. If you continue to experience problems registering, please contact us.";
                showErrorMessage.value = true;
            }
        } catch (err) {
            await store.dispatch('setLoading', {id: 'create-account', state: false})
            //console.log(err);
            errorMessage.value = "reCaptcha error. Please try again. If you continue to experience problems registering, please contact us.";
            showErrorMessage.value = true;
        }
    }

    const checkEmailAddress = async () => {
        showErrorMessage.value = false;
        if(!email.value || !email.value.includes('@') ) {
            await store.dispatch('setLoading', {id: 'create-account', state: false})
            errorMessage.value = 'The email address you entered <b><span style="font-style:italic;">is not valid</span></b>. Please try again.';
            showErrorMessage.value = true;
        } else {
            try {
                // This should never be accessed as the signIn will always fail
                // we just need to know if it fails on password or non-existent
                // account, which is handled in the catch
                const isUser = await Auth.signIn(email.value.toLowerCase(),'null');
                await store.dispatch('setLoading', {id: 'create-account', state: false})
                console.log(isUser);
            } catch(err) {
                const message = err.message;
                //console.log(message);
                if(message.includes('Incorrect username or password')) {
                    await store.dispatch('setLoading', {id: 'create-account', state: false})
                    errorMessage.value = 'The e-mail address <b><span style="font-style:italic;">"'+ email.value +'"</span></b> is already registered. <a href="/forgottenpassword"><span style="color:#282B2F;text-decoration:underline;">Have you forgotten your password?</span></a>';
                    showErrorMessage.value = true;
                } else if(message.includes('User is not confirmed')) {
                    await store.dispatch('setLoading', {id: 'create-account', state: false})
                    try {
                        await Auth.resendSignUp(email.value.toLowerCase());
                    } catch (err) {
                        console.log('error resending code: ', err);
                    }
                    errorMessage.value = 'The e-mail address <b><span style="font-style:italic;">"'+ email.value +'"</span></b> is already registered, but verification is required.<br>Please check your inbox and verify by clicking on the link in the email. This link will expire in 5 days.</a>';
                    showErrorMessage.value = true;
                } else {
                    await store.dispatch('setLoading', {id: 'create-account', state: false})
                    const data = {email: email.value.toLowerCase()};
                    const result = await store.dispatch('storeNewUserEmail',data);
                    if(result) {
                        router.push('/createAccountDetail');
                    }
                }
            }
        }
    }

    const showEmailInfo = (event) => {
        console.log('email info text popover');
        op.value.toggle(event);
    }

    const closeError = () => {
        if(showErrorMessage.value) {
            showErrorMessage.value = false;
        }
    }

    const loadingState = computed(() => store.getters.isLoading('create-account'))

    return {
        email,
        op,
        errorMessage,
        showErrorMessage,
        checkEmailAddress,
        showEmailInfo,
        recaptcha,
        closeError,
        loadingState
    }
  }
}
</script>

<style scoped lang="scss">
    #createAccount {
        width: 610px;
    }
    Button, .buttonwidth {
        width: 300px !important;
    }
    .recaptcha-terms {
        background-color: var(--clarity-pure-white);
        padding: 10px 20px 10px 20px;
        width: 365px;
        margin-top: 20px;
        border-radius: 10px;
        border: 1px solid var(--clarity-light-grey);
    }
    .recaptcha-terms p {
        margin: 0px;
        color: var(--clarity-grey);
    }
    .recaptcha-terms a {
        color: var(--clarity-light-blue);
    }
    .create-error-msg {
        background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #D1313880;
        border-radius: 27px;
        margin-bottom: 30px;
        padding: 32px 30px 35px 30px;
    }
    .create-error-msg p {
        margin-left: 35px;
        margin-bottom: 0px;
    }
    .create-error-msg-link {
        color: var(--clarity-dark-grey) !important;
        text-decoration: underline !important;
    }
    .material-icons {
        vertical-align: top;
        margin-right: 10px !important;
    }
    .cl-info-icon {
        cursor: pointer;
    }
</style>
